<template>
  <div class="agreement">
    	<h4 style="text-align:center;">{{ name }}用户服务协议</h4>
		<p>欢迎您使用{{ name }}服务！</p>
		<p>您在使用{{ name }}提供的各项服务之前，请您务必审慎阅读、充分理解本协议各条款内容。</p>
		<p class="agreement_title">1. 服务条款的确认及接受</p>
		<p class="agreement_cont">1.1 {{ name }}各项电子服务的所有权、知识产权和运营权均归属于{{ name }}，其提供的服务将完全按照其发布的服务条款和操作规则严格执行。</p>
		<p class="agreement_cont">1.2 您确认所有服务条款并完成注册程序时，将视为您签署了本协议，表明自愿接受本协议全部条款的约束，本协议将构成您与{{ name }}及其主办的{{ name }}（包含{{ name }}移动客户端、在线开放平台及其他现在或未来开发或升级换代的自有平台、网站，以下合称为“{{ name }}”）之间直接有约束力的法律文件，同时您成为{{ name }}正式用户。</p>
		<p class="agreement_cont">1.3根据国家法律法规的变化及{{ name }}运营需要，{{ name }}有权对本协议条款及相关规则不时地进行修改，修改后的内容一旦以任何形式公布在{{ name }}相应平台上则立即生效，并取代此前相关内容。您应不时关注{{ name }}公告、提示信息及协议、规则等相关内容的变动。如您不同意本协议及/或随时对其的修改，您可以主动停止使用{{ name }}提供的服务；您一旦使用{{ name }}服务，即视为您已了解并完全同意本协议各项内容，包括{{ name }}对本协议随时所做的任何修改。</p>
		<p class="agreement_title">2．服务内容</p>
		<p class="agreement_cont">2.1 {{ name }}服务的具体内容由{{ name }}根据实际运营情况提供，若您对{{ name }}服务的具体内容有疑义，可致电{{ name }}客户服务电话予以询问。</p>
		<p class="agreement_cont">2.2 您理解并接受通过{{ name }}得到的资讯、产品及服务均系{{ name }}自动搜索的结果，鉴于电信运营商网络服务、现有技术水平及其他不可控因素影响，{{ name }}不保证搜索结果必然满足您的要求，不保证搜索服务不中断，对搜索结果的安全性、正确性、及时性、合法性均不做担保。</p>
		<p class="agreement_cont">2.3 鉴于网络服务的特殊性，您同意{{ name }}有权不经事先通知，随时变更、中断或终止部分或全部的网络服务（包括收费网络服务），且无需承担赔偿责任。{{ name }}不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。</p>
		<p class="agreement_cont">2.4 {{ name }}会定期或不定期地对提供服务的平台或相关的设备进行检修、维护或升级，此类情况发生之前，{{ name }}将会提前通过短信、平台消息公告或推送等不同方式向您发送提醒通知。</p>
		<p class="agreement_cont">2.5 免责声明：因以下情况造成{{ name }}网络服务在合理时间内的中断，{{ name }}无需为此承担任何责任；</p>
		<span>2.5.1因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；</span>
		<span>2.5.2用户的电脑软硬件和通信线路、供电线路出现故障的；</span>
		<span>2.5.3因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因。尽管有前款约定，{{ name }}将采取合理行动积极促使服务恢复正常。</span>
		<p class="agreement_cont">2.6 第三方责任：若您在使用{{ name }}提供的相应服务的过程中，因可归责于第三方的原因而导致您遭受损失的，则{{ name }}会协助您与第三方解决问题，为您提供帮助与支持。</p>
		<p class="agreement_title">3．会员帐号及密码</p>
		您注册会员成功后，{{ name }}将给予您一个帐号及动态密码，帐号由您负责保管；您应当对以您帐号所从事的所有活动和行为负法律责任。
		因黑客行为或您保管疏忽致使帐号被他人非法使用的，{{ name }}不承担任何责任。如您发现任何非法使用会员帐号或安全漏洞的情况，请立即与{{ name }}联系。</p>
		<p class="agreement_title">4. 注册信息和隐私保护</p>
		<p class="agreement_cont">4.1 {{ name }}帐号（即{{ name }}用户ID）的所有权归{{ name }}，您完成注册申请手续后，获得{{ name }}帐号的使用权。您应提供及时、详尽及准确的个人资料，并不断更新注册资料，并保证注册资料符合及时、详尽、准确的要求。所有原始键入的资料均作为注册资料。如果因您键入资料不真实而引起的问题及其产生的后果，由您自行承担相应的责任，{{ name }}不负任何责任。</p>
		<p class="agreement_cont">4.2 您不得将其帐号、密码转让或出借予他人使用。如发现其帐号遭他人非法使用，您应立即通知{{ name }}。</p>
		<p class="agreement_cont">4.3 {{ name }}不对外公开或向第三方提供单个用户的注册资料、支付信息等，但以下情况除外：</p>
		<span>• 事先获得您的明确授权；</span>
		<span>• 只有披露您的个人信息，才能提供您所需要的产品和服务；</span>
		<span>• 根据有关法律法规的要求，依照国家机关，公安、检察、法院等司法机关的命令或要求；</span>
		<span>• 按照国家机关、法院、仲裁机构裁定或命令的要求；</span>
		<span>• 在发生法律规定的其他不可抗力情况下，{{ name }}必须对外披露的。</span>
		<p class="agreement_cont">4.4 在您注册{{ name }}帐户，使用{{ name }}产品或服务时，{{ name }}会收集您的个人身份识别资料，并会将这些资料用于：改进为您提供的服务及网页内容、或者以持续向您提供更优质服务为目的提供给{{ name }}关联公司或{{ name }}合作的第三方。您对此予以理解并确认同意。</p>
		<p class="agreement_title">5. 用户权责</p>
		<p class="agreement_cont">5.1 您有权按照{{ name }}规定的程序和标准使用{{ name }}提供的各项服务，若您对该服务有异议，可与{{ name }}联系以便得到及时解决。</p>
		<p class="agreement_cont">5.2 您同意接受{{ name }}、{{ name }}关联公司、或{{ name }}授权的第三方通过电子邮件或其他方式向您发送相关服务推广信息。</p>
		<p class="agreement_cont">5.3 您在使用{{ name }}服务时，必须遵守中华人民共和国相关法律法规的规定，不得利用本服务进行任何违法或不正当的活动，包括但不限于下列行为∶</p>
		<p style="padding-left:1em;">5.3.1 制作、复制、发布、传播或以其它方式传送含有下列内容之一的信息：</p>
		<span>•反对宪法所确定的基本原则的</span>
		<span>•危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</span>
		<span>•损害国家荣誉和利益的；</span>
		<span>•煽动民族仇恨、民族歧视、破坏民族团结的；</span>
		<span>•破坏国家宗教政策，宣扬邪教和封建迷信的；</span>
		<span>•散布谣言，扰乱社会秩序，破坏社会稳定的；</span>
		<span>•散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</span>
		<span>•侮辱或者诽谤他人，侵害他人合法权利的；</span>
		<span>•煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</span>
		<span>•以非法民间组织名义活动的；</span>
		<span>•含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</span>
		<span>•含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的。</span>
		<p class="agreement_cont">5.4如您在使用服务时违反任何上述规定，{{ name }}有权要求您改正或直接采取一切法律允许的必要措施（包括但不限于暂停或终止您使用服务的权利）以减轻或消除您不当行为造成的影响。</p>
		<p class="agreement_cont">5.5 您须对自己在使用{{ name }}服务过程中的行为承担法律责任。您承担法律责任的形式包括但不限于：对受到侵害者进行赔偿；以及在{{ name }}首先承担了因您行为导致的行政处罚或侵权损害赔偿责任后，您应给予{{ name }}等额的赔偿；并承担法律规定的其他法律责任。</p>
		<p class="agreement_title">6. 知识产权</p>
		<p class="agreement_cont">6.1 {{ name }}依法享有相应的知识产权，包括但不限于著作权、商标权、专利权或其它专属权利等，受到相关法律的保护。未经{{ name }}明示授权，上述资料均不得用于任何商业目的。</p>
		<p class="agreement_cont">6.2 {{ name }}为提供服务而使用的任何软件（包括但不限于软件中所含的任何图象、照片、动画、录像、录音、音乐、文字和附加程序、随附的辅助材料）的一切权利均属于{{ name }}，未经{{ name }}许可，您不得对该软件进行反向工程（reverse engineer）、反向编译（decompile）或反汇编（disassemble）。</p>
		<p class="agreement_title">7. 其他</p>
		<p class="agreement_cont">7.1 因不可抗力或者其他意外事件，使得本协议的履行不可能、不必要或者无意义的，双方均不承担责任。本协议所称之不可抗力意指不能预见、不能避免并不能克服的客观情况，包括但不限于战争、台风、水灾、火灾、雷击或地震、罢工、暴动、法定疾病、黑客攻击、网络病毒、电信部门技术管制、政府行为或任何其它自然或人为造成的灾难等客观情况。</p>
		<p class="agreement_cont">7.2 本条款及其修订本的有效性、履行和与本条款及其修订本效力有关的所有事宜，将受中华人民共和国法律约束，任何争议仅适用中华人民共和国法律。</p>
		<p class="agreement_cont">7.3 因本条款所引起的您与{{ name }}的任何纠纷或争议，首先应友好协商解决，协商不成的，您在此同意将纠纷或争议提交{{ name }}所在地人民法院诉讼解决。</p>
		<p class="agreement_cont">7.4 您正确提交注册程序所需的资料并确认本协议后，本协议在{{ name }}与您之间成立并生效。</p>
		<p class="agreement_cont">7.5 协议有效期：从您同意本协议或使用{{ name }}起至您注销{{ name }}服务止。</p>
		<p class="agreement_cont">7.6 本协议如无特殊规定，双方应当使用电子邮件方式就本协议相关事项进行联系。</p>
		<p class="agreement_cont">7.7 {{ name }}各相关部门的电子邮箱以在{{ name }}官方网站上注明的为准。</p>
		<p class="agreement_cont">7.8 本协议的最终解释权归{{ name }}所有。</p>
  </div>
</template>

<script>

export default {
  name: 'Agreement',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      name: '临境物联科技成都有限公司'
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  destroyed () {},
  methods: {
  }
}
</script>

<style scoped lang="scss">
.agreement{
    padding: 0 20px;
    font-size: 24px;
    text-align: left;
    .agreement_title{

    }
    .agreement_cont{

    }
}
</style>