<template>
  <div class="details">
    <div class="order-details">
      <div class="title">{{consumptionType}}</div>
      <img class="img-icon" src="@/assets/image/order-baoyue.png" alt="">
      <p class="price">￥ {{ amountPayed }}</p>
      <ul class="details-ul">
        <li><span>名称：</span><span class="text">{{ incomeName }}</span></li>
        <li><span>支付时间：</span><span class="text">{{ payTime }}</span></li>
        <li><span>支付方式：</span><span class="text">{{ payType }}</span></li>
        <li><span>交易单号：</span><span class="text">{{ dataId }}</span></li>
        <li><span>平台单号：</span><span class="text">{{ tradeId }}</span></li>
      </ul>
    </div>
    <div class="question" @click="toFeedback">
      <img class="left-icon" src="@/assets/image/wenti.png" alt="">
      <div @click="toFeedback">
        <span class="title">对订单有疑问</span><span class="des">有什么问题，在这里可以联系客服哟~</span>
      </div>
      <img class="right-icon" src="@/assets/image/home-right.png" alt="">
    </div>
  </div>
</template>

<script>
import { apiOrderInfo } from "@/utils/api"
import { Toast, Notify } from 'vant'

export default {
  name: 'Details',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      incomeName: '',
      payTime: '',
      payType: '',
      dataId: '',
      tradeId: '',
      amountPayed: '',
      consumptionType: '消费类型'
    }
  },
  computed: {},
  watch: {},
  created () {
    const urlParam = this.$route.query
    this.tradeId = urlParam.tradeId
    this.getOrderInfo(this.tradeId)
  },
  mounted () {},
  destroyed () {},
  methods: {
    getOrderInfo (tradeId) {
      let that = this
      const requestP = {
        tradeId: tradeId,
      };
      apiOrderInfo(requestP)
        .then(result => {
          if (result.status == "200") {
            that.incomeName = result.data.incomeName
            that.payTime = result.data.payTime
            that.payType = result.data.payType
            that.dataId = result.data.dataId
            that.tradeId = result.data.tradeId
            that.amountPayed = result.data.amountPayed
            that.consumptionType = result.data.consumptionType
          } else {

          }
        })
        .catch(err => {
          Notify({ type: "danger", message: err })
        })
    },
    toFeedback (){
      let qParam = {
        tradeId: this.tradeId
      }
      this.$router.push({
        path: "/feedback",
        query: qParam
      })
    }
  },
}
</script>

<style scoped lang="scss">
.details{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .order-details{
    margin-top: 47px;
    width: 725px;
    height: 849px;
    background: url('../../assets/image/order-di.png');
    background-size: cover;
    .title{
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #343434;
      line-height: 90px;
    }
    .img-icon{
      margin: 36px 0 20px;
      width: 120px;
      height: 120px;
    }
    .price{
      font-size: 44px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FE6402;
      margin: 0;
    }
    .details-ul{
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #343434;
      text-align: left;
      width: 660px;
      margin: 25px auto 0;
      li{
        border-bottom: 1px solid #E5E5E5;
        height: 70px;
        line-height: 70px;
        margin-top: 14px;
        span{
          margin-left: 22px;
        }
        span.text{
          color: #666666;
          margin-left: 0px;
        }
      }
    }
  }
  .question{
    width: 690px;
    height: 143px;
    background: #FFFFFF;
    box-shadow: 1px 4px 20px 0px rgba(174, 186, 190, 0.7);
    border-radius: 6px;
    margin-top: 33px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    .left-icon{
      width: 60px;
      height: 60px;
    }
    .right-icon{
      width: 12px;
      height: 23px;
      position: absolute;
      right: 54px;
    }
    div{
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-left: 17px;
      .title{
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #424242;
      }
      .des{
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #9A9A9A;
      }
    }
  }
}
</style>