<template>
  <div class="login">
    <div class="head">
      <img class="wx-head-img" src="" alt="">
    </div>
    <div class="login-form">
      <div class="form-item">
        <img class="img-phone" src="@/assets/image/login-phone.png" alt="">
        <input type="text" class="phone" v-model="phone" placeholder="请输入手机号" maxlength="11">
      </div>
      <div class="form-item">
        <img class="img-testing" src="@/assets/image/login-testing.png" alt="">
        <input type="text" class="verify-code" v-model="verifyCode" placeholder="请输入验证码" maxlength="4">
        <span class="verify-code-btn" @click="getVerifyCode">获取验证码</span>
      </div>
      <van-button class="linear-gradient login-btn" type="info" @click="login">
        登录
      </van-button>
      <span class="notification">登录即表示您已阅读并同意<a href="">《泊士停车服务协议》</a></span>
    </div>
    <van-dialog class="verify-code-dialog" v-model="verifyCodeDialog" show-cancel-button :before-close="beforeClose">
      <span class='dialog-span'>即将发送验证码到</span>
      <p>{{ setPhone }}</p>
      <span class='dialog-span'>稍后您将收到系统发送的验证码，请将<br/>验证码填写到验证码输入框<br/>以完成注册或登录</span>
    </van-dialog>
  </div>
</template>

<script>
import { apiRegisterLogin } from "@/utils/api"
import { Toast, Dialog } from 'vant'

export default {
  name: 'Login',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      phone: '',
      verifyCode: '',
      verifyCodeDialog: false
    }
  },
  computed: {
    setPhone () {
      return this.phone.slice(0,3) + '****' + this.phone.substr(7,4)
    }
  },
  watch: {},
  created () {},
  mounted () {},
  destroyed () {},
  methods: {
    login () {
      this.$router.push({path:'/'})
    },
    getVerifyCode () {
      if(this.phone.length != 11){
        Toast('请输入手机号');
        return
      }
      this.verifyCodeDialog = true
    },
    beforeClose (action, done) {
      if (action === 'confirm') {
        console.log('[点击事件 - 备注] - 确认');

        done(); // 关闭提示框
      } else if (action === 'cancel') { // 取消
        done(); // 关闭提示框
      }
    },
  }
}
</script>

<style scoped lang="scss">
.login{
  width: 100%;
  height: 100vh;
  background: url('../../assets/image/login-di.png');
  background-size: cover;
  .head{
    padding-top: 200px;
    .wx-head-img{
      width: 128px;
      height: 128px;
      display: inline-block;
      border-radius: 50%;
      border: 1px solid #d0ddfe;
      margin-bottom: 48px;
    }
  }
  .login-form{
    margin: 0 auto;
    width: 544px;
    height: 515px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 0px rgba(174, 185, 190, 0.7);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .form-item{
      width: 473px;
      height: 69px;
      border: 1px solid #E5E5E5;
      border-radius: 4px;
      margin-bottom: 55px;
      line-height: 1;
      font-size: 24px;
      display: flex;
      align-items: center;
      position: relative;
      input{
        border: none;
        height: 24px;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
      }
      .img-phone{
        width: 30px;
        height: 43px;
        margin-left: 20px;
        margin-right: 17px;
      }
      .img-testing{
        width: 35px;
        height: 35px;
        margin-left: 18px;
        margin-right: 14px;
      }
      .verify-code-btn{
        height: 22px;
        font-size: 23px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
        position: absolute;
        right: 0;
        padding: 22px 18px;
      }
    }
    .login-btn{
      width: 473px;
      height: 70px;
      border-radius: 35px;
      margin-bottom: 30px;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
    }
    .notification{
      width: 357px;
      height: 16px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 34px;
      a{
        color: #73b7ce;
      }
    }
  }
  .verify-code-dialog{ 
    border-radius: 8px;
    ::v-deep .van-dialog__content{
      padding: 55px 26px 40px;
      .dialog-span{
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #9A9A9A;
        line-height: 48px;
      }
      p{
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
      }
    }
  }
}
</style>