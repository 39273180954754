<template>
  <div class="bind-phone">
    <div class="head">
      <img class="wx-heae-img" :src="userHeadImg" alt="">
    </div>
    <div class="login-form">
      <div class="form-item">
        <img class="img-phone" src="@/assets/image/login-phone.png" alt="">
        <input type="text" class="phone" v-model="phone" placeholder="请输入手机号" maxlength="11">
      </div>
      <div class="form-item">
        <img class="img-testing" src="@/assets/image/login-testing.png" alt="">
        <input type="text" class="verify-code" v-model="verifyCode" placeholder="请输入验证码" maxlength="6">
        <span class="verify-code-btn" @click="clickFlag && getVerifyCode()">{{ codeBtnText }}</span>
      </div>
      <van-button class="linear-gradient login-btn" type="info" @click="bindPhone">
        绑 定
      </van-button>
      <span class="notification">绑定即表示您已阅读并同意<a @click="toAgreement">《泊士停车服务协议》</a></span>
    </div>
    <van-dialog class="verify-code-dialog" v-model="verifyCodeDialog" show-cancel-button :before-close="beforeClose">
      <span class='dialog-span'>即将发送验证码到</span>
      <p>{{ setPhone }}</p>
      <span class='dialog-span'>稍后您将收到系统发送的验证码，请将<br/>验证码填写到验证码输入框<br/>以完成手机号绑定</span>
    </van-dialog>
  </div>
</template>

<script>
import { apiVerificationCode, SendSms, updateUserPhone } from "@/utils/api"
import { Toast, Dialog, Notify } from 'vant'

export default {
  name: 'BindPhone',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      phone: '',
      verifyCode: '',
      verifyCodeDialog: false,
      userHeadImg: '',
      timer: null,
      codeBtnText: '获取验证码',
      clickFlag: true,
      isGetCode: false
    }
  },
  computed: {
    setPhone () {
      return this.phone.slice(0,3) + '****' + this.phone.substr(7,4)
    }
  },
  watch: {},
  created () {
    this.userHeadImg = JSON.parse(localStorage.getItem('userInfo')).headimgurl
  },
  mounted () {},
  destroyed () {},
  methods: {
    sendCode() {
      let that = this;

      let times = 60;
      const param = {
        phone: this.phone,
        smsTemplate: 6
      }
      SendSms(param)
        .then(result => {
          if (result.status == "200") {
            that.isGetCode = true
            that.timer = setInterval(function() {
              times--;
              if (times == 0 || times < 0) {
                that.codeBtnText = "获取验证码"
                that.clickFlag = true
                clearInterval(that.timer)
                that.timer = null
              } else {
                that.codeBtnText = "重新获取" + times + "s"
                that.clickFlag = false
              }
            }, 1000);
            this.verifyCodeDialog = false
            Notify({
              message: "验证码已发送,请注意查收",
              background: "#16AF99"
            });
          } else {
            Notify({ type: "danger", message: res.message })
          }
        })
        .catch(err => {
          window.console.log(err);
        })
    },
    getVerifyCode () {
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        Notify({ type: "danger", message: "请填写正确的手机号" })
        return
      }
      this.verifyCodeDialog = true
    },
    beforeClose (action, done) {
      if (action === 'confirm') {
        this.sendCode()
        done()
      } else if (action === 'cancel') {
        done()
      }
    },
    bindPhone (){
      let that = this
      if(!this.isGetCode){
        Toast('请获取短信验证码')
        return
      }
      if(this.verifyCode.length != 6){
        Notify({ type: "danger", message: "请填写6位验证码" })
        return
      }
      let param = {
        unionId: localStorage.getItem('unionid'),
        phoneMobile: this.phone,
        verCode: this.verifyCode
      }
      updateUserPhone(param)
        .then(result => {
          if (result.status == "200") {
            localStorage.setItem("phone", that.phone)
            that.$router.push('/')
          } else {
            Notify({ type: "danger", message: result.message })
          }
        })
        .catch(err => {
          window.console.log(err);
        })
    },
    toAgreement (){
      this.$router.push('/agreement')
    }
  }
}
</script>

<style scoped lang="scss">
.bind-phone{
  width: 100%;
  height: 100vh;
  background: url('../../assets/image/login-di.png');
  background-size: cover;
  .head{
    padding-top: 200px;
    .wx-heae-img{
      width: 128px;
      height: 128px;
      display: inline-block;
      border-radius: 50%;
      margin-bottom: 48px;
    }
  }
  .login-form{
    margin: 0 auto;
    width: 544px;
    height: 515px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 0px rgba(174, 185, 190, 0.7);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .form-item{
      width: 473px;
      height: 69px;
      border: 1px solid #E5E5E5;
      border-radius: 4px;
      margin-bottom: 55px;
      line-height: 1;
      font-size: 24px;
      display: flex;
      align-items: center;
      position: relative;
      input{
        border: none;
        height: 55px;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
      }
      input.phone{
        width: 100%;
      }
      .img-phone{
        width: 30px;
        height: 43px;
        margin-left: 20px;
        margin-right: 17px;
      }
      .img-testing{
        width: 35px;
        height: 35px;
        margin-left: 18px;
        margin-right: 14px;
      }
      .verify-code-btn{
        height: 22px;
        font-size: 23px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
        position: absolute;
        right: 0;
        padding: 22px 18px;
      }
    }
    .login-btn{
      width: 473px;
      height: 70px;
      border-radius: 35px;
      margin-bottom: 30px;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
    }
    .notification{
      width: 357px;
      height: 16px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 34px;
      a{
        color: #73b7ce;
      }
    }
  }
  .verify-code-dialog{ 
    border-radius: 8px;
    ::v-deep .van-dialog__content{
      padding: 55px 26px 40px;
      .dialog-span{
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #9A9A9A;
        line-height: 48px;
      }
      p{
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
      }
    }
  }
}
</style>