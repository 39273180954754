<template>
  <div class="add-car">
    <img class="head-bg-img" src="@/assets/image/bjpay.png" alt="">
    <p class="title">添加车牌</p>
    <div class="carlicense-box">
      <span class="caption">请输入车牌号添加</span>
      <div class="carlicense-spans">
        <span @click="showCnKeyBoard" class="">{{carlicenseArr[0] ? carlicenseArr[0] : ''}}</span>
        <span @click="showKeyBoard" class="">{{carlicenseArr[1] ? carlicenseArr[1] : ''}}</span>
        <span class="dot">·</span>
        <span @click="showKeyBoard" class="">{{carlicenseArr[2] ? carlicenseArr[2] : ''}}</span>
        <span @click="showKeyBoard" class="">{{carlicenseArr[3] ? carlicenseArr[3] : ''}}</span>
        <span @click="showKeyBoard" class="">{{carlicenseArr[4] ? carlicenseArr[4] : ''}}</span>
        <span @click="showKeyBoard" class="">{{carlicenseArr[5] ? carlicenseArr[5] : ''}}</span>
        <span @click="showKeyBoard" class="">{{carlicenseArr[6] ? carlicenseArr[6] : ''}}</span>
        <span v-if="newEnergyCar" @click="showKeyBoard" class="">{{carlicenseArr[7] ? carlicenseArr[7] : ''}}</span>
        <span v-else class="new-span" @click="changeCarType"><b>+</b><br/><b class="text">新能源</b></span>
      </div>
    </div>
    <van-button class="linear-gradient next-btn" type="info" @click="confirmDialog">
      下一步
    </van-button>
    <van-dialog class="bind-dialog" v-model="bindDialog" show-cancel-button :before-close="beforeClose">
      <p class="carlincese">{{ setCarlicense }}</p>
      <span class='dialog-span'>请确认车牌信息无误，确认后此车将绑定到您的账户</span>
    </van-dialog>
    <KeyBoard
      :showKeyBoard="show"
      :showCNKeyBoard="showCn"
      v-on:input-over="hideKeyBoard"
      v-on:change-press="changeKeyBoard"
      v-on:key-press="getPressKeyValue"
      v-on:del-press="delCarKeyPress"
      v-on:clear-press="clearCarInput"
    />
  </div>
</template>

<script>
import KeyBoard from "@/components/KeyBoard"
import { apiBindCar } from "@/utils/api"
import { Notify } from 'vant'

export default {
  name: 'AddCar',
  mixins: [],
  components: {
    KeyBoard
  },
  props: {},
  data () {
    return {
      bindDialog: false,
      show: false,
      showCn: true,
      init: true,
      loading: false,
      showCertDialog: false,
      newEnergyCar: false,
      disabledBindBtn: true,
      carlicenseArr: [],
      carLength: 7
    }
  },
  computed: {
    setCarlicense () {
      let carlicense = this.carlicenseArr.join("")
      return carlicense.slice(0,2) + '·' + carlicense.slice(2)
    }
  },
  watch: {
    carlicenseArr: function() {
      const len = this.carlicenseArr.length;
      len == 0 && ((this.showCn = true), (this.init = true));
      len == 1 && ((this.showCn = false), (this.init = false));
      len > 6 && (this.disabledBindBtn = false);
      len < 7 && (this.disabledBindBtn = true);
    }
  },
  created () {
    if (this.carlicenseArr.length == 0) {
      this.showCn = true;
    } else if (this.carlicenseArr.length == 1) {
      this.showCn = false;
    }
  },
  mounted () {},
  destroyed () {},
  methods: {
    beforeClose (action, done) { // 点击事件 - 备注按钮提示框
    let that =this
      if (action === 'confirm') { // 确认
        let param = {
          registrationId: localStorage.getItem('registrationId'),
          carLicense: this.carlicenseArr.join(""),
        }
        apiBindCar(param)
          .then(result => {
            if (result.status == "200") {
              that.$router.push('/cars')
            } else {
              Notify({ type: "danger", message: result.message })
            }
          })
          .catch(err => {
            window.console.log(err);
          })
        done(); // 关闭提示框
      } else if (action === 'cancel') { // 取消
        done(); // 关闭提示框
      }
    },
    confirmDialog () {
      // todo 获取车牌，验证
      var xreg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/;
      var creg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/;

      if (this.carlicenseArr.length < 7) {
        Notify({ type: "danger", message: '请输入完整的车牌' })
        return
      }
      let carlicense = this.carlicenseArr.join("")
      if (this.carlicenseArr.length == 7 && !creg.test(carlicense)) {
          Notify({ type: "danger", message: '请输入正确的车牌' })
          return
      }
      if (this.carlicenseArr.length == 8 && !xreg.test(carlicense)) {
        Notify({ type: "danger", message: '请输入正确的新能源车牌' })
        return 
      }
      this.bindDialog = true
    },
    changeCarType() {
      this.newEnergyCar = true;
      this.carLength = 8;
    },
    showKeyBoard() {
      this.show = true;
    },
    showCnKeyBoard() {
      this.show = true;
      this.showCn = true;
      this.carlicenseArr = [];
    },
    //键盘点击完成，收起键盘
    hideKeyBoard() {
      this.show = false;
    },
    //获取按键值
    getPressKeyValue(val) {
      let car = this.carlicenseArr.join("") + val;
      if (car.length > this.carLength) {
        window.console.log("车牌长度超出");
        return;
      }
      this.carlicenseArr = car.split("");
    },
    //切换中英文键盘
    changeKeyBoard() {
      this.showCn = !this.showCn;
    },
    //按下退格键
    delCarKeyPress() {
      let car = this.carlicenseArr;
      car.pop();
      this.carlicenseArr = car;
    },
    //按下清空键
    clearCarInput() {
      this.carlicenseArr = [];
    },
  },
}
</script>

<style scoped lang="scss">
.add-car{
  .head-bg-img{
    width: 100%;
    height: 330px;
  }
  .title{
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    position: absolute;
    left: 60px;
    top: 150px;
  }
  .carlicense-box{
    width: 690px;
    height: 271px;
    background: #FFFFFF;
    box-shadow: 1px 4px 20px 0px rgba(174, 186, 190, 0.7);
    border-radius: 10px;
    margin: 0 auto;
    position: relative;
    top: -80px;
    .caption{
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #272727;
      margin-bottom: 60px;
      margin-top: 44px;
      display: inline-block;
    }
    .carlicense-spans{
      display: flex;
      justify-content: space-between;
      padding: 0 29px;
      span{
        width: 62px;
        height: 84px;
        border: 1px solid #CCCCCC;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #343434;
        &:active{
          border: 1px solid #26A0A6;
        }
      }
      span.active{
        border: 1px solid #26A0A6;
      }
      span.dot{
        width: 40px;
        border: none;
        color: #cccccc;
        font-size: .6rem;
      }
      .new-span{
        display: inline-block;
        font-size: 10px;
        color: #999999;
        b{
          font-size: 30px;
        }
        b.text{
          font-size: 15px;
          font-weight: 300;
        }
      }
    }
  }
  .next-btn{
    width: 600px;
    height: 80px;
    background: linear-gradient(-67deg, #26A0A6, #8EE9C6);
    border-radius: 4px;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }
  .bind-dialog{ 
    border-radius: 8px;
    ::v-deep .van-dialog__content{
      padding: 46px 28px 27px;
      .dialog-span{
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #9A9A9A;
        line-height: 48px;
      }
      .carlincese{
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
        margin: 0;
        margin-bottom: 36px;
      }
    }
  }
}
</style>