<template>
  <div class="monthly">
    <h1>月租服务,功能开发中...</h1>
  </div>
</template>

<script>

export default {
  name: 'Monthly',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      aaaa: '12312321'
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  destroyed () {},
  methods: {},
}
</script>

<style scoped lang="scss">

</style>