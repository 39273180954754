<template>
  <div class="orders">
    <div class="bg">
      <div class="head-bg"></div>
      <div class="line"></div>
      <div class="container-bg"></div>
    </div>
    <van-tabs 
      v-model="active"
      color='#fff'
      background="none"
      title-inactive-color="#ffffff"
      title-active-color="#fff"
      @click="toggleTab"
    >
      <van-tab v-for="item in orderType" :title="item.dictLabel" :name="item.dictValue" :key="item.dictValue"></van-tab>
    </van-tabs>
    <!-- 展示订单列表 -->
    <div class="order-list-box">
      <van-pull-refresh
        v-model="refreshing" 
        @refresh="onRefresh"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="order-list" ref="container">
            <div class="order-item" v-for="item in lists" :key="item.dataId" @click="toDetails(item.tradeId)">
              <div class="left">
                <img src="@/assets/image/order-tingchechang.png" alt="">
                <div class="order-info">
                  <span class="order-name">{{ item.incomeName }}</span>
                  <span class="order-address">{{ item.parkinglotName }}</span>
                </div>
              </div>
              <div class="right">
                <span class="order-price">￥{{ item.amountPayed }}</span>
                <span class="order-date">{{ item.payTime }}</span>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { apiDict, apiOrderLists } from "@/utils/api"
import { JudgeUA, currentDate, monthObj } from "@/utils/common"
import { Toast, Notify } from 'vant'

export default {
  name: 'Orders',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      active: 0,
      down: false,
      isXiala: true,
      riseTime: '',
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      container: null,
      maxDate: new Date(),
      currentDate: new Date(),
      orderType: [],
      incomeCategory: '',
      currentPage: 1,
      initMonth: '',
      currentMonthCount: 0,
      lists: []
    }
  },
  computed: {        

  },
  watch: {},
  created () {
    let now = currentDate()
    this.payTime = now.year + '-' + now.month

    this.getOrderType()

  },
  mounted () {},
  destroyed () {},
  methods: {
    getOrderType (){
      let that = this
      const requestP = {
        dictType: 'incomeCategory',
      };
      apiDict(requestP)
        .then(result => {
          if (result.status == "200") {
            that.orderType = result.data
            that.incomeCategory = result.data[0].dictValue
            // that.getOrderLists()
          } else {

          }
        })
        .catch(err => {
          Notify({ type: "danger", message: err })
        })
    },
    getOrderLists (){
      console.log('查询订单')
      let that = this
      const requestP = {
        registrationId: localStorage.getItem('registrationId'),
        currentPage: this.currentPage,
        pageSize: 20,
        incomeCategory: this.incomeCategory,
        payTime: '',
        down: this.down ? 1 : ''
      };
      apiOrderLists(requestP)
        .then(result => {
          if (result.status == "200") {
            that.renderData(result)
          } else {
            Notify({ type: "danger", message: result.message })
          }
        })
        .catch(err => {
          Notify({ type: "danger", message: err })
        })
    },
    renderData (data){
      let that = this
      let hasData = data.data == null? true: false

      let isEmptyListsObj = JSON.stringify(this.lists) == '{}'? true : false

      if(hasData){
        that.finished = true
        Toast("没有更多数据了")
        return
      }

      that.loading = false
      data.data.forEach(element => {
        that.lists.push({
          entranceTime: element.entranceTime, 
          exitTime: element.exitTime,
          dataId: element.dataId,
          tradeId: element.tradeId,
          parkinglotName: element.parkinglotName,
          payTime: element.payTime,
          incomeName: element.incomeName,
          amountPayed: element.amountPayed,
          incomeCategory: element.incomeCategory,
        })
      })

      if(that.lists.length < data.rowCount){
        that.currentPage++
      }else{
        that.currentPage = 1
        that.finished = true
      }
    },
    toggleTab (name, title){
      this.lists = []
      this.incomeCategory = name
      this.currentPage = 1
      this.loading = false
      this.finished = false
    },
    onLoad() {
      console.log('上拉')
      this.down = false
      setTimeout(() => {
        this.getOrderLists()
      }, 1000)
    },
    toDetails (item) {
      this.$router.push({
        path: "/details",
        query: {tradeId: item}
      })
    },
  },
}
</script>

<style scoped lang="scss">
.orders{
  .bg{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: -1;
    .head-bg{
      width: 100%;
      height: 250px;
      top: 0;
      background: #26A0A6;
    }
    .line{
      width: 732px;
      height: 18px;
      background: #29868A;
      border-radius: 9px;
      position: absolute;
      top: 150px;
    }
    .container-bg{
      width: 711px;
      height: calc(100vh - 180px);
      background: #FFFFFF;
      box-shadow: 1px 4px 20px 0px rgba(174, 186, 190, 0.7);
      border-radius: 0px 0px 14px 14px;
      position: absolute;
      top: 159px;
    }
  }
  .van-tabs{
    ::v-deep .van-tabs__wrap{
      padding-top: 30px;
      height: 80px;
      .van-tab{
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 34px;
      }
    }
    ::v-deep .van-tabs__content{
      // background: #000;
      // padding: 0 20px;
      // margin-top: 50px;
    }
  }
  .order-list-box{
    margin-top: 100px;
    max-height: calc(100vh - 260px);
    overflow-y: auto;
    padding: {
      left: 30px;
      right: 30px;
    };
    .van-pull-refresh{
      min-height: calc(100vh - 260px);
      // margin-top: 30px;
    }
    .order-list{
      // margin-top: 24px;
      .order-item{
        height: 135px;
        border-bottom: 1px solid rgba(145, 145, 145, .7);
        padding: 36px 14px 18px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        .left{
          text-align-last: left;
          display: flex;
          align-items: center;
          img{
            width: 80px;
            height: 80px;
            margin-right: 20px;
          }
          .order-info{
            display: flex;
            flex-direction: column;
            .order-name{
              font-size: 28px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #222222;
              margin-bottom: 16px;
            }
            .order-address{
              font-size: 22px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #A2A2A2;
            }
          }
        }
        .right{
          text-align: right;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .order-price{
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FF2C2C;
            margin-bottom: 20px;
          }
          .order-date{
            font-size: 22px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #A2A2A2;
          }
        }
      }
    }
  }
}
</style>