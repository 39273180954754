<template>
  <div class="feedback">
    <p class="title">问题类别</p>
    <div class="type-box">
      <span class="type" v-for="item in questionType" :key="item.dictValue" :class="selctedType == item.dictValue?'selected':''" @click="selectQuestionType(item.dictValue)"><img src="@/assets/image/dui.png" alt=""> {{item.dictLabel}}</span>
      <!-- <span class="type" :class="questionType == 1?'selected':''" @click="selectQuestionType(1)"><img src="@/assets/image/dui.png" alt=""> 重复缴费</span>
      <span class="type" :class="questionType == 2?'selected':''" @click="selectQuestionType(2)"><img src="@/assets/image/dui.png" alt=""> 其它问题</span> -->
    </div>
    <p class="title">问题补充描述</p>
    <van-field
      v-model="des"
      rows="5"
      type="textarea"
      maxlength="100"
      placeholder="请填写10字以上的描述信息，以便客服人员快速帮你解决问题。"
      show-word-limit
    />
    <p class="title" style="margin-bottom:0;">联系方式</p>
    <div class="form">
      <div class="form-item">
        <label for="">联系人（选填）：</label>
        <input type="text" maxlength="11" v-model="phone" placeholder="请输入手机号">
      </div>
      <div class="form-item">
        <label for="">验证码：</label>
        <input type="text" v-model="verifyCode" maxlength="6" placeholder="请输入验证码">
        <span class="verify-code-btn" @click="getVerifyCode">获取验证码</span>
      </div>
    </div>
    <van-button class="linear-gradient submit-btn" type="info" @click="toSubmit">
      提交
    </van-button>
  </div>
</template>

<script>
import { apiDict, questionFeedback, SendSms } from "@/utils/api"
import { Toast, Notify } from 'vant'

export default {
  name: 'Feedback',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      tradeId: '',
      des: '',
      phone: '',
      verifyCode: '',
      questionType: [],
      selctedType: null,
      isPhone: false
    }
  },
  computed: {},
  watch: {},
  created () {
    const urlParam = this.$route.query
    this.tradeId = urlParam.tradeId
    this.getQuestionType()
  },
  mounted () {},
  destroyed () {},
  methods: {
    getQuestionType (){
      let that = this
      const requestP = {
        dictType: 'orderQuestionsType',
      }
      apiDict(requestP)
        .then(result => {
          if (result.status == "200") {
            that.questionType = result.data
            that.selctedType = result.data[0].dictValue
          } else {

          }
        })
        .catch(err => {
          Notify({ type: "danger", message: err })
        })
    },
    selectQuestionType (type) {
      this.selctedType = type
    },
    getVerifyCode (){
      if(!/^((13|14|15|16|17|18|19){1}\d{9}|([0-9]{3,4}\-{1})[1-9]{1}[0-9]{6,7})$/.exec(this.phone)){
        Toast('请输入正确手机号！')
        return
      }

      const requestP = {
        phone: this.phone,
        smsTemplate: 2,
      }
      SendSms(requestP)
        .then(result => {
          if (result.status == "200") {
            Notify({ type: "success", message: '短信验证码已发送，请注意查收' })
          } else {
            Notify({ type: "danger", message: result.message })
          }
        })
        .catch(err => {
          Notify({ type: "danger", message: err })
        })

    },
    toSubmit (){
      if(this.des.length < 10){
        Toast('请输入不少于10字问题描述！')
        return
      }
      if(this.phone != "" && this.verifyCode == ''){
        Toast('请输入验证码！')
        return
      }
      if(this.phone == "" && this.verifyCode != ''){
        Toast('请输入手机号！')
        return
      }

      const requestP = {
        orderQuestionsType: this.selctedType,
        orderQuestionsPhone: this.phone,
        remarks: this.des,
        orderCode: this.tradeId,
        verCode: this.verifyCode
      }
      questionFeedback(requestP)
        .then(result => {
          if (result.status == "200") {
            Notify({ type: "success", message: '提交成功' })
          } else {
            Notify({ type: "danger", message: result.message })
          }
        })
        .catch(err => {
          Notify({ type: "danger", message: err })
        })

    }
  },
}
</script>

<style scoped lang="scss">
.feedback{
  padding: 0 30px;
  text-align: left;
  p.title{
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #0E0E10;
    line-height: 32px;
    margin-top: 48px;
    margin-bottom: 38px;
  }
  .type-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .type{
      width: 210px;
      height: 60px;
      background: #F7F7FA;
      border-radius: 4px;
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #9B9B9B;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 30px;
        height: 30px;
        margin-right: 16px;
      }
    }
    .type.selected{
      background: linear-gradient(90deg, #26A0A5 0%, #8DE9C6 100%);
      color: #FFFFFF;
    }
  }
  .van-field{
    width: 690px;
    // height: 300px;
    background: #F7F7FA;
    border-radius: 4px;
  }
  .form{
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    .form-item{
      height: 84px;
      box-sizing: border-box;
      padding-top: 14px;
      padding: {
        left: 27px;
        right: 27px;
      };
      line-height: 70px;
      border-bottom: 1px solid  #E5E5E5;
      input{
        height: 50px;
        line-height: 50px;
        border: none;
      }
      .verify-code-btn{
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FE6402;
        padding: 20px;
        line-height: 1;
        position: absolute;
        right: 27px;
      }
    }
  }
  .submit-btn{
    width: 600px;
    height: 80px;
    background: linear-gradient(-67deg, #26A0A5, #8DE9C6);
    border-radius: 4px;
    margin: 110px 45px 0;
  }
}
</style>