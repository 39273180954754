<template>
  <div class="orders">
    <div class="bg">
      <div class="head-bg"></div>
      <div class="line"></div>
      <div class="container-bg"></div>
    </div>
    <van-tabs 
      v-model="active"
      color='#fff'
      background="none"
      title-inactive-color="#ffffff"
      title-active-color="#fff"
      @click="toggleTab"
    >
      <van-tab v-for="item in orderType" :title="item.dictLabel" :name="item.dictValue" :key="item.dictValue"></van-tab>
    </van-tabs>
    <div class="order-list-box">
      <van-pull-refresh
        v-model="refreshing" 
        @refresh="onRefresh"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="month-box" v-for="(value, name, index) in lists" :key="index">
            <van-sticky :container="container" :offset-top="100">
              <div class="month-statistics" @click="selectMonth">
                <span class="month-text">{{ formatter2(value.month?value.month:'') }}</span><span class="consumption-total">(共消费：￥{{ value.amountPayed }}元)<van-icon name="arrow-down" /></span>
              </div>
            </van-sticky>
            <div class="order-list" ref="container">
              <div class="order-item" v-for="item in value.orderlist" :key="item.dataId" @click="toDetails(item.tradeId)">
                <div class="left">
                  <img src="@/assets/image/order-tingchechang.png" alt="">
                  <div class="order-info">
                    <span class="order-name">{{ item.incomeName }}</span>
                    <span class="order-address">{{ item.parkinglotName }}</span>
                  </div>
                </div>
                <div class="right">
                  <span class="order-price">￥{{ item.amountPayed }}</span>
                  <span class="order-date">{{ item.payTime }}</span>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <van-popup 
      v-model="show"
      position="bottom"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        :min-date="this.minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @confirm="getPayTime"
        @cancel="show=false"
      />
    </van-popup>
  </div>
</template>

<script>
import { apiDict, apiOrderLists } from "@/utils/api"
import { JudgeUA, currentDate, monthObj } from "@/utils/common"
import { Toast, Notify } from 'vant'

export default {
  name: 'Orders',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      active: 0,
      down: false,
      isXiala: true,
      riseTime: '',
      flag: 1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      container: null,
      // minDate: new Date(2020, 0, 1), 
      maxDate: new Date(),
      currentDate: new Date(),
      show: false,
      orderType: [],
      incomeCategory: '',
      currentPage: 1,
      payTime: '',
      initMonth: '',
      currentMonthCount: 0,
      lists: {}
    }
  },
  computed: {        
    minDate(){
      let curDate = (new Date()).getTime()
      let one = 365 * 24 * 3600 * 1000 * 2
      let towYearAgo = curDate - one
      return new Date(towYearAgo)
    }
  },
  watch: {},
  created () {
    let now = currentDate()
    this.payTime = now.year + '-' + now.month
    this.initMonth = this.payTime

    this.getOrderType()

  },
  mounted () {},
  destroyed () {},
  methods: {
    getOrderType (){
      let that = this
      const requestP = {
        dictType: 'incomeCategory',
      };
      apiDict(requestP)
        .then(result => {
          if (result.status == "200") {
            that.orderType = result.data
            that.incomeCategory = result.data[0].dictValue
            // that.getOrderLists(1)
          } else {

          }
        })
        .catch(err => {
          Notify({ type: "danger", message: err })
        })
    },
    // 查询订单列表
    // flag : 1上拉加载  2下拉加载  3点击月份查询
    getOrderLists (flag){
      let that = this
      const requestP = {
        registrationId: localStorage.getItem('registrationId'),
        currentPage: this.currentPage,
        pageSize: 20,
        incomeCategory: this.incomeCategory,
        payTime: this.payTime,
        down: this.down ? 1 : ''
      };
      apiOrderLists(requestP)
        .then(result => {
          if (result.status == "200") {
            that.renderData(result.data, flag)
          } else {
            Notify({ type: "danger", message: result.message })
          }
        })
        .catch(err => {
          Notify({ type: "danger", message: err })
        })
    },
    /**
     * 渲染页面
     * 1、点击选择年月查询
     * 2、上拉加载：需判断是否当前月、或者加载下一个月，涉及分页数据，需判断最小日期
     * 3、下拉加载，同上，还需判断最大查询日期
     */
    renderData (data, flag){
      let that = this
      let orderlistArr = []
      let hasData = data == null? true: false

      let isEmptyListsObj = JSON.stringify(this.lists) == '{}'? true : false

      if(hasData && that.payTime == ''){
        that.finished = true
        Toast("没有更多数据了")
        return
      }

      // 根据类型走不同的处理流程
      switch (flag) {
        case 1: // 上拉，默认
          that.loading = false
          if(hasData){
            that.payTime = ''
            // that.payTime = monthObj(that.payTime).last
            // that.finished = true
          }else{
            if(that.lists[data.payTime]){
              data.info.forEach(element => {
                that.lists[that.payTime].orderlist.push({
                    entranceTime: element.entranceTime, 
                    exitTime: element.exitTime,
                    dataId: element.dataId,
                    tradeId: element.tradeId,
                    parkinglotName: element.parkinglotName,
                    payTime: element.payTime,
                    incomeName: element.incomeName,
                    amountPayed: element.amountPayed,
                    incomeCategory: element.incomeCategory,
                })
              })
            }else{
              data.info.forEach(element => {
                orderlistArr.push({
                    entranceTime: element.entranceTime, 
                    exitTime: element.exitTime,
                    dataId: element.dataId,
                    tradeId: element.tradeId,
                    parkinglotName: element.parkinglotName,
                    payTime: element.payTime,
                    incomeName: element.incomeName,
                    amountPayed: element.amountPayed,
                    incomeCategory: element.incomeCategory,
                })
              })

              let newObj = {}
              newObj[data.payTime] = {
                month: data.payTime,
                amountPayed: data.amountPayed,
                count: data.count,
                orderlist: orderlistArr
              }
              that.lists = Object.assign({}, that.lists, newObj)
            }

            // 判断是否下一页
            if(that.lists[data.payTime].orderlist.length < data.count){
              that.currentPage++
            }else{
              that.currentPage = 1
              if(data.dropTime == ''){
                that.payTime = data.payTime
                that.finished = true
              }else{
                that.payTime = data.dropTime
              }
            }
          }

          break;
        case 2: // 下拉
            that.initMonth = data.payTime
            if(that.lists[data.payTime]){
              data.info.forEach(element => {
                that.lists[that.payTime].orderlist.unshift({
                    entranceTime: element.entranceTime, 
                    exitTime: element.exitTime,
                    dataId: element.dataId,
                    tradeId: element.tradeId,
                    parkinglotName: element.parkinglotName,
                    payTime: element.payTime,
                    incomeName: element.incomeName,
                    amountPayed: element.amountPayed,
                    incomeCategory: element.incomeCategory,
                })
              })
            }else{
              data.info.forEach(element => {
                orderlistArr.unshift({
                    entranceTime: element.entranceTime, 
                    exitTime: element.exitTime,
                    dataId: element.dataId,
                    tradeId: element.tradeId,
                    parkinglotName: element.parkinglotName,
                    payTime: element.payTime,
                    incomeName: element.incomeName,
                    amountPayed: element.amountPayed,
                    incomeCategory: element.incomeCategory,
                })
              })

              let newObj = {}
              newObj[data.payTime] = {
                month: data.payTime,
                amountPayed: data.amountPayed,
                count: data.count,
                orderlist: orderlistArr
              }
              that.lists = Object.assign({}, newObj, that.lists)
            }

            // 判断是否下一页
            if(that.lists[data.payTime].orderlist.length < data.count){
              that.currentPage++
            }else{
              that.currentPage = 1
              if(data.riseTime == ''){
                that.payTime = data.payTime
                that.isXiala = true
              }else{
                that.payTime = data.riseTime
              }
            }
          break;
      
        default:
          if(!hasData){
            if(data.riseTime != ''){
              that.isXiala = false
              that.riseTime = data.riseTime
            }else{
              that.isXiala = true
            }
            that.loading = false
            that.lists = {}
            data.info.forEach(element => {
              orderlistArr.push({
                  entranceTime: element.entranceTime, 
                  exitTime: element.exitTime,
                  dataId: element.dataId,
                  parkinglotName: element.parkinglotName,
                  payTime: element.payTime,
                  incomeName: element.incomeName,
                  amountPayed: element.amountPayed,
                  incomeCategory: element.incomeCategory,
              })
            })

            that.$set(that.lists, that.payTime, {
              month: data.payTime,
              amountPayed: data.amountPayed,
              count: data.count,
              orderlist: orderlistArr
            })
          }else{
            Toast('未查询到相关订单信息！')
          }
          break;
      }
    },
    toggleTab (name, title){
      this.lists = {}
      this.incomeCategory = name
      this.currentPage = 1
      this.payTime = this.initMonth
      this.loading = false
      this.finished = false
    },
    onLoad() {
      this.down = false
      if(this.flag == 1){
        setTimeout(() => {
          this.getOrderLists(1)
        }, 1000)
      }
    },
    onRefresh() {
      if(this.isXiala){
        this.refreshing = false
        Toast('已是最新了！')
        return
      }

      setTimeout(() => {
        this.down = true
        this.payTime = this.riseTime
        this.getOrderLists(2)
        this.refreshing = false
      }, 1000)
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },
    formatter2(val) {
      if(val.length == 7){
        return val.substr(0,4)+ '年' +val.substr(5,2)+'月'
      } else {
        return val
      }
    },
    selectMonth () {
      this.show = true
    },
    toDetails (item) {
      this.$router.push({
        path: "/details",
        query: {tradeId: item}
      })
    },
    getPayTime (value){
      this.flag = 3
      let y = value.getFullYear()
      let m = value.getMonth()+1
      this.payTime = y+'-'+ (m < 10 ? '0'+m: m)
      this.initMonth = this.payTime
      this.currentPage = 1
      this.show = false
      this.loading = false
      this.finished = false
      this.getOrderLists(1)
    }
  },
}
</script>

<style scoped lang="scss">
.orders{
  .bg{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: -1;
    .head-bg{
      width: 100%;
      height: 250px;
      top: 0;
      background: #26A0A6;
    }
    .line{
      width: 732px;
      height: 18px;
      background: #29868A;
      border-radius: 9px;
      position: absolute;
      top: 150px;
    }
    .container-bg{
      width: 711px;
      height: calc(100vh - 180px);
      background: #FFFFFF;
      box-shadow: 1px 4px 20px 0px rgba(174, 186, 190, 0.7);
      border-radius: 0px 0px 14px 14px;
      position: absolute;
      top: 159px;
    }
  }
  .van-tabs{
    ::v-deep .van-tabs__wrap{
      padding-top: 30px;
      height: 80px;
      .van-tab{
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 34px;
      }
    }
    ::v-deep .van-tabs__content{
      // background: #000;
      // padding: 0 20px;
      // margin-top: 50px;
    }
  }
  .order-list-box{
    margin-top: 100px;
    max-height: calc(100vh - 260px);
    overflow-y: auto;
    padding: {
      left: 30px;
      right: 30px;
    };
    .van-pull-refresh{
      min-height: calc(100vh - 260px);
      // margin-top: 30px;
    }
    .month-box{
      .month-statistics{
        height: 80px;
        line-height: 80px;
        text-align-last: left;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3b2b2b;
        background-color: #fff;
        width: 690px;
        margin: 0 auto;
        box-sizing: border-box;
        .month-text{
          border-left: 5px solid #26A0A6;
          padding-left: 12px;
          margin-left: 14px;
        }
        .consumption-total{
          font-size: 26px;
          color: #646f84;
          margin-left: 20px;
        }
      }
      .order-list{
        // margin-top: 24px;
        .order-item{
          height: 135px;
          border-bottom: 1px solid rgba(145, 145, 145, .7);
          padding: 36px 14px 18px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          .left{
            text-align-last: left;
            display: flex;
            align-items: center;
            img{
              width: 80px;
              height: 80px;
              margin-right: 20px;
            }
            .order-info{
              display: flex;
              flex-direction: column;
              .order-name{
                font-size: 28px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #222222;
                margin-bottom: 16px;
              }
              .order-address{
                font-size: 22px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #A2A2A2;
              }
            }
          }
          .right{
            text-align: right;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .order-price{
              font-size: 30px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #FF2C2C;
              margin-bottom: 20px;
            }
            .order-date{
              font-size: 22px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #A2A2A2;
            }
          }
        }
      }
    }
  }
}
</style>