<template>
  <div class="cars">
    <div class="no-car" v-show="noCars">
      <img src="@/assets/image/chahua.png" alt="">
      <span>暂无内容~</span>
    </div>
    <div class="car-box" v-for="item in carlist" :key="item.bindingId">
      <div class="left">
        <img src="@/assets/image/car-licenseplate.png" alt="">
        <span class="carlincese">{{item.carLicense}}</span>
      </div>
      <img src="@/assets/image/car-shanchu.png" alt="" class="right-del" @click="unbind(item)">
    </div>
    <van-button class="linear-gradient add-car-btn" type="info" @click="toAddCar">
      添加车牌
    </van-button>
    <van-dialog class="unbind-dialog" v-model="unbindDialog" show-cancel-button :before-close="beforeClose">
      <p>确定要移除<span class="carlincese">{{ unbindCar }}</span>吗？</p>
      <span class='dialog-span'>移除后，历史消费记录仍然可以查询，移除后将不再享受更多便捷服务了哟~</span>
    </van-dialog>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
import { apiCarlist, apiDelCar } from "@/utils/api"

export default {
  name: 'Cars',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      carlist: [],
      noCars: false,
      unbindDialog: false,
      unbindCar: '',
      unbindCarBindingId: ''
    }
  },
  computed: {},
  watch: {
    carlist: function() {
      const len = this.carlist.length
      this.noCars = len == 0 ? true : false
    }
  },
  created () {
    this.getCarList()
  },
  mounted () {},
  destroyed () {},
  methods: {
    getCarList (){
      let that = this
      let param = {
        registrationId: localStorage.getItem('registrationId'),
        currentPage: 1,
        pageSize: 20
      }
      apiCarlist(param)
        .then(result => {
          if (result.status == "200") {
            that.carlist = result.data
          } else {
            Notify({ type: "danger", message: res.message })
          }
        })
        .catch(err => {
          window.console.log(err);
        })
    },
    unbind (data) {
      this.unbindCar = data.carLicense
      this.unbindCarBindingId = data.bindingId
      this.unbindDialog = true
    },
    beforeClose (action, done) { // 点击事件 - 备注按钮提示框
      let that = this
      if (action === 'confirm') { // 确认
        let param = {
          registrationId: localStorage.getItem('registrationId'),
          bindingId: this.unbindCarBindingId
        }
        apiDelCar(param)
          .then(result => {
            if (result.status == "200") {
              that.getCarList()
              Toast.success('车辆解绑成功')
            } else {
              Notify({ type: "danger", message: res.message })
            }
          })
          .catch(err => {
            window.console.log(err);
          })

        done(); // 关闭提示框
      } else if (action === 'cancel') { // 取消
        done(); // 关闭提示框
      }
    },
    toAddCar () {
      this.$router.push('/addcar')
    }
  },
}
</script>

<style scoped lang="scss">
.cars{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .no-car{
    img{
      width: 394px;
      height: 338px;
      display: block;
      margin-top: 140px;
    }
    span{
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
    }
  }
  .car-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 690px;
    height: 143px;
    background: #FFFFFF;
    box-shadow: 1px 4px 20px 0px rgba(174, 186, 190, 0.7);
    border-radius: 6px;
    margin-top: 40px;
    padding: {
      left: 39px;
    };
    box-sizing: border-box;
    .left{
      font-size: 34px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #343434;
      display: flex;
      align-items: center;
      img{
        width: 80px;
        height: 80px;
        margin-right: 28px;
      }
    }
    .right-del{
      width: 39px;
      height: 39px;
      padding: 35px;
    }
  }
  .add-car-btn{
    width: 600px;
    height: 80px;
    background: linear-gradient(-67deg, #26A0A6, #8EE9C6);
    border-radius: 4px;
    margin-top: 95px;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }
  .unbind-dialog{ 
    border-radius: 8px;
    ::v-deep .van-dialog__content{
      padding: 46px 28px 27px;
      .dialog-span{
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #9A9A9A;
        line-height: 48px;
      }
      p{
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
        margin: 0;
        margin-bottom: 36px;
        .carlincese{
          color: #2a9db3;
          margin: 0 5px;
        }
      }
    }
  }
}
</style>