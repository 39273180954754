import { render, staticRenderFns } from "./feedback.vue?vue&type=template&id=29347a87&scoped=true&"
import script from "./feedback.vue?vue&type=script&lang=js&"
export * from "./feedback.vue?vue&type=script&lang=js&"
import style0 from "./feedback.vue?vue&type=style&index=0&id=29347a87&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29347a87",
  null
  
)

export default component.exports